import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../API/baseURL";

const Register = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate(); // Initialize useNavigate


  const handleSignUp = async () => {
    try {
      setLoading(true); // Set loading to true when signup begins
        // Basic form validation
        if (!fullName || !email || !password) {
          toast.error("Please fill in all fields.", {
            style: { maxWidth: "60vw", height: "5vh" },
          });
          return;
        }
  
        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          toast.error("Please enter a valid email address.", {
            style: { maxWidth: "60vw", height: "5vh" },
          });
          return;
        }

      // Construct the request body
      const requestBody = {
        fullname: fullName,
        email: email,
        password: password,
        role: "user",
      };

      // Make the POST request using Axios
      const response = await axios.post(`${API}auth/register`, requestBody);

      // Check if the request was successful (status code 2xx)
      if (response.status === 200) {
        // Handle success, e.g., redirect to another page or show a success message
        console.log("Registration successful!");
        localStorage.setItem("rashAIToken", response.data.accessToken)

        navigate("/");

        toast.success("Registration successful! ", {
          style: { maxWidth: "60vw", height: "5vh" },
        });
      } else {
        // Handle errors, e.g., show an error message to the user
        console.log("Registration successful:", response.data);
        localStorage.setItem("rashAIToken", response.data.accessToken)
        console.log({response})
        navigate("/");

        toast.success(
          response?.response?.data?.message[0] || "Registration successful",
          {
            style: { maxWidth: "60vw", height: "5vh" },
          }
        );
      }
    } catch (error) {
      // Handle other errors, e.g., network issues
      console.error("Error during registration:", error.message);
      console.log({error})
      toast.error(error?.response?.data?.message,{
        style: { maxWidth: "60vw", height: "5vh" },
      });
      toast.error( error?.response?.data?.message?.[0] || "Something Went Wrong", {
        style: { maxWidth: "60vw", height: "5vh" },
      });
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  return (
    <div className="container-xxl">
      <ToastContainer />
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* Register Card */}
          <div className="card">
            <div className="card-body">
              {/* Logo */}
              <div className="app-brand justify-content-center">
                <a href="https://www.rashfordai.com/" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <svg
                      width="25"
                      viewBox="0 0 25 42"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      {/* SVG paths and definitions */}
                    </svg>
                  </span>
                  <span className="app-brand-text demo text-body fw-bolder">
                    Rash.ai
                  </span>
                </a>
              </div>
              <form
                id="formAuthentication"
                className="mb-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignUp();
                }}
              >
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder="Enter your username"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="form-control"
                      name="password"
                      placeholder="Enter your password"
                      aria-describedby="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={showPassword ? "bx bx-show" : "bx bx-hide"}
                      ></i>
                    </span>
                  </div>
                 </div>
                <button
                  type="submit"
                  className={`btn btn-primary d-grid w-100 ${
                    loading ? "disabled" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Signing up..." : "Sign up"}
                </button>
              </form>
              {/* </form> */}

              <p className="text-center">
                <span>Already have an account?</span>
                <a href="/login">
                  <span>Sign in instead</span>
                </a>
              </p>
            </div>
          </div>
          {/* Register Card */}
        </div>
      </div>
    </div>
  );
};

export default Register;
