import React from "react";

const Navbar = () => {
  return (
    <React.Fragment>
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
             
              <div className="navbar-nav align-items-center">
                <div className="nav-item d-flex align-items-center">
               
                  rash.ai(LOGO)
                </div>
              </div>

              

              <ul className="navbar-nav flex-row align-items-center ms-auto">
                <li className="nav-item lh-1 me-3">
              
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvxpKLpI66pv8pKcqJJDJOmdlMT9JfIVgFFZT93cFvYGOChAoUZmicXAxCfV1Mw9MM0ZM&usqp=CAU" style={{width: "3rem", height: "3rem"}} alt="normally na" className="rounded-circle" />
                </li>

             
              </ul>
            </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
