// Import necessary modules
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screen/Home";
import Signin from "./screen/SignIn";
import Register from "./screen/SignUp";
import ForgotPassword from "./screen/ForgottenPassword";
import GrammerCheck from "./screen/GrammerCheck";
import PlagarismCheck from "./screen/PlagarismCheck";
import SummaryGenerator from "./screen/SummaryGenerator";
import CitationGenerator from "./screen/CitationGenerator";
import Traslator from "./screen/Translator";
import SuperAssistant from "./screen/SuperAssistant";
import ProofreadGenerator from "./screen/Proofread";
import PunctuationCheck from "./screen/PunctuationCheck";
import EssayCheck from "./screen/EssayChecker";
import SpellCheck from "./screen/SpellChecker";
import WordCount from "./screen/WordCount";

// Define routes using Route element
const App = () => (
  <div>
    <Router>
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/login" element={<Signin/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/reset" element={<ForgotPassword/>} />
        <Route path="/grammer-check" element={<GrammerCheck/>} />
        <Route path="/plagiarism-check" element={<PlagarismCheck/> } />
        <Route path="/summary-generator" element={<SummaryGenerator/> } />
        <Route path="/citation-generator" element={<CitationGenerator/> } />
        <Route path="/translator" element={<Traslator/> } />
        <Route path="/super-assistant" element={<SuperAssistant/> } />
        <Route path="/proofreading" element={<ProofreadGenerator/> } />
        <Route path="/punctuation-check" element={<PunctuationCheck/> } />
        <Route path="/essay-check" element={<EssayCheck/> } />
        <Route path="/spell-check" element={<SpellCheck/> } />
        <Route path="/word-count" element={<WordCount/> } />


      </Routes>
    </Router>
    
  </div>
);

export default App;
