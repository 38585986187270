// import React from "react";

// const SignIn = () => {
//   return (
//     <div className="container-xxl">
//       <div className="authentication-wrapper authentication-basic container-p-y">
//         <div className="authentication-inner">
//           {/* Register */}
//           <div className="card">
//             <div className="card-body">
//               {/* Logo */}
//               <div className="app-brand justify-content-center">
//                 <a href="https://1rash-ai.vercel.app/" className="app-brand-link gap-2">
//                   <span className="app-brand-logo demo">
//                     <svg
//                       width="25"
//                       viewBox="0 0 25 42"
//                       version="1.1"
//                       xmlns="http://www.w3.org/2000/svg"
//                       xmlnsXlink="http://www.w3.org/1999/xlink"
//                     >
//                       {/* SVG paths and definitions */}
//                     </svg>
//                   </span>
//                   <span className="app-brand-text demo text-body fw-bolder">
//                     Rash.ai
//                   </span>
//                 </a>
//               </div>
//               {/* /Logo */}
//               {/* <h4 className="mb-2">Welcome to Sneat! 👋</h4>
//               <p className="mb-4">Please sign-in to your account and start the adventure</p> */}

//               {/* <form
//                 id="formAuthentication"
//                 className="mb-3"
//                 action="https://1rash-ai.vercel.app/"
//                 method="POST"
//               > */}
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">
//                     Email
//                   </label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email-username"
//                     placeholder="Enter your email or username"
//                     autoFocus
//                   />
//                 </div>
//                 <div className="mb-3 form-password-toggle">
//                   <div className="d-flex justify-content-between">
//                     <label className="form-label" htmlFor="password">
//                       Password
//                     </label>
//                     <a href="/reset">
//                       <small>Forgot Password?</small>
//                     </a>
//                   </div>
//                   <div className="input-group input-group-merge">
//                     <input
//                       type="password"
//                       id="password"
//                       className="form-control"
//                       name="password"
//                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
//                       aria-describedby="password"
//                     />
//                     <span className="input-group-text cursor-pointer">
//                       <i className="bx bx-hide"></i>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="mb-3">
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="checkbox"
//                       id="remember-me"
//                     />
//                     <label className="form-check-label" htmlFor="remember-me">
//                       Remember Me
//                     </label>
//                   </div>
//                 </div>
//                 <div className="mb-3">
//                   <a href="/">
//                     <button
//                       className="btn btn-primary d-grid w-100"
//                       type="submit"
//                     >
//                       Sign in
//                     </button>
//                   </a>
//                 </div>
//               {/* </form> */}

//               <p className="text-center">
//                 <span>New on our platform?</span>
//                 <a href="/register">
//                   <span>Create an account</span>
//                 </a>
//               </p>
//             </div>
//           </div>
//           {/* /Register */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignIn;




import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../API/baseURL";

const SignIn = () => {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      setLoading(true);

        // Basic form validation
        if (!emailOrUsername || !password) {
          toast.error("Please fill in all fields.", {
            style: { maxWidth: "60vw", height: "5vh" },
          });
          return;
        }
  
        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailOrUsername)) {
          toast.error("Please enter a valid email address.", {
            style: { maxWidth: "60vw", height: "5vh" },
          });
          return;
        }

      const requestBody = {
        email: emailOrUsername,
        password: password,
      };

      const response = await axios.post(`${API}auth/login`, requestBody);

      if (response.status === 200) {
        console.log("Login successful!");
        localStorage.setItem("rashAIToken", response.data.accessToken)

        // Do something on successful login, e.g., redirect to another page
        navigate("/");
        toast.success("Login successful!", {
          style: { maxWidth: "60vw", height: "5vh" },
        });
      } else {
        localStorage.setItem("rashAIToken", response.data.accessToken)

        console.error("Login successful!", response.data);
        navigate("/");

        toast.success(
          response?.response?.data?.message || "Login successful!",
          {
            style: { maxWidth: "60vw", height: "5vh" },
          }
        );
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      toast.error(
        error?.response?.data?.message || "Something Went Wrong",
        {
          style: { maxWidth: "60vw", height: "5vh" },
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-xxl">
      <ToastContainer />
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* SignIn */}
          <div className="card">
            <div className="card-body">
              {/* Logo */}
              <div className="app-brand justify-content-center">
                <a href="https://www.rashfordai.com/" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <svg
                      width="25"
                      viewBox="0 0 25 42"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      {/* SVG paths and definitions */}
                    </svg>
                  </span>
                  <span className="app-brand-text demo text-body fw-bolder">
                    Rash.ai
                  </span>
                </a>
              </div>
              {/* /Logo */}
              {/* <h4 className="mb-2">Welcome to Sneat! 👋</h4>
              <p className="mb-4">Please sign-in to your account and start the adventure</p> */}

              <form
                id="formAuthentication"
                className="mb-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignIn();
                }}
              >
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email or Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email-username"
                    placeholder="Enter your email or username"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    autoFocus
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <a href="/reset">
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="form-control"
                      name="password"
                      placeholder="Enter your password"
                      aria-describedby="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={showPassword ? "bx bx-show" : "bx bx-hide"}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <button
                    className={`btn btn-primary d-grid w-100 ${
                      loading ? "disabled" : ""
                    }`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Signing in..." : "Sign in"}
                  </button>
                </div>
              </form>

              <p className="text-center">
                <span>New on our platform?</span>
                <a href="/register">
                  <span>Create an account</span>
                </a>
              </p>
            </div>
          </div>
          {/* /SignIn */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
