import React from 'react';

const ForgotPassword = () => {
  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
          {/* Forgot Password */}
          <div className="card">
            <div className="card-body">
              {/* Logo */}
              <div className="app-brand justify-content-center">
                <a href="https://www.rashfordai.com/" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <svg
                      width="25"
                      viewBox="0 0 25 42"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      {/* SVG paths and definitions */}
                    </svg>
                  </span>
                  <span className="app-brand-text demo text-body fw-bolder">Rash.ai</span>
                </a>
              </div>
              {/* /Logo */}
              <h4 className="mb-2">Forgot Password? 🔒</h4>
              {/* <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p> */}
              <form id="formAuthentication" className="mb-3" action="https://www.rashfordai.com/" method="POST">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    autoFocus
                  />
                </div>
                <button className="btn btn-primary d-grid w-100" type="submit">
                  Send Reset Link
                </button>
              </form>
              <div className="text-center">
                <a href="/login" className="d-flex align-items-center justify-content-center">
                  <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                  Back to login
                </a>
              </div>
            </div>
          </div>
          {/* /Forgot Password */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
