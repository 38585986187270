import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Navigator from "../components/Navigator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CitationGenerator() {
  const [originalText, setOriginalText] = useState("");
  const [paraphrasedText, setParaphrasedText] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [isLoading, setIsLoading] = useState(false); // New loading state

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleParaphrase = async () => {
    try {
      setIsLoading(true); // Set loading state to true

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer sk-V6p18ULdiMPfAayzKqoaT3BlbkFJng6dZxFGwSv95bA7ZxpKADD`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "user",
                // content: `Create a citation for this: ${originalText}`,
                content: `Generate a citation for the given content, and provide a reference list according to the specified format (e.g., APA, Harvard). Ensure the citation is accurate and the reference list is formatted correctly: ${originalText}`,
              },
            ],
            temperature: 0.7,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error.message || "Failed to get completion");
      }

      console.log(data.choices[0].message.content);
      setParaphrasedText(data.choices[0].message.content);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle error, e.g., show a toast
    } finally {
      setIsLoading(false); // Set loading state back to false after API call
    }
  };

  const handleCopyToClipboard = () => {
    if (paraphrasedText) {
      navigator.clipboard
        .writeText(paraphrasedText)
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          toast.error(`Error copying to clipboard: ${error.message}`);
        });
    } else {
      toast.error("Nothing to copy. Paraphrased text is empty.");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className="layout-wrapper layout-content-navbar"
        // style={{ padding: "0 5rem" }}
      >
        <div className="layout-container">
          <div className="layout-page">
            {/* <!-- Navbar --> */}

            <Navbar />

            {/* <!-- / Navbar --> */}

            {/* <!-- Content wrapper --> */}
            <div className="content-wrapper">
              {/* <!-- Content --> */}

              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-md-12">
                    <Navigator activeTab="citationGenerator" />
                  </div>
                  <div class="col-xl">
                    <div class="card mb-4">
                      <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Citation Generator</h5>
                        <small class="text-muted float-end">Paste Text</small>
                      </div>

                      <div class="card-body">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="basic-icon-default-message"
                          >
                            Input
                          </label>
                          <div class="input-group input-group-merge">
                            <textarea
                              id="basic-icon-default-message"
                              class="form-control"
                              placeholder="Paste Your Content Here"
                              aria-label="Paste Your Content Here"
                              aria-describedby="basic-icon-default-message2"
                              value={originalText}
                              onChange={(e) => setOriginalText(e.target.value)}
                              rows={isLargeScreen ? 7 : 2}
                            ></textarea>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleParaphrase}
                          disabled={isLoading} // Disable button when loading
                        >
                          {isLoading ? "Generating..." : "Generate"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl">
                    <div class="card mb-4">
                      <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Outcome</h5>
                      </div>
                      <div class="card-body">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="basic-icon-default-message"
                          >
                            Result
                          </label>
                          <div class="input-group input-group-merge">
                            <textarea
                              id="basic-icon-default-message"
                              class="form-control"
                              value={paraphrasedText}
                              aria-describedby="basic-icon-default-message2"
                              rows={isLargeScreen ? 13 : 10}
                              contentEditable="false"
                              style={{
                                overflowY: "auto",
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          onClick={handleCopyToClipboard}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- / Content --> */}

              <div className="content-backdrop fade"></div>
            </div>
            {/* <!-- Content wrapper --> */}
          </div>
          {/* <!-- / Layout page --> */}
        </div>

        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </React.Fragment>
  );
}

export default CitationGenerator;
