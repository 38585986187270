import React from "react";
import {
  AiOutlineEdit,
  AiOutlineCheck,
  AiOutlineCopy,
  AiOutlineFile,
  AiOutlineNumber,
  AiOutlineSearch,
  AiOutlineRobot,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineFileText,
} from "react-icons/ai";

const Navigator = ({ activeTab }) => {
  return (
    <React.Fragment>
     

      <ul
        className="na.v nav-pills flex-row mb-3"
        style={{
          display: "flex",
          overflowX: "auto",
          // whiteSpace: "nowrap",
          textAlign: "center",
          width: "100%",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
          padding: "1rem",
          fontSize: "0.7rem",
          fontWeight: "500",
          borderRadius: "10px"
        }}
      >
        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "paraphraser" ? "active" : ""
            }`}
            href="/"
          >
            <AiOutlineEdit className="me-2" /> Paraphraser
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "grammarCheck" ? "active" : ""
            }`}
            href="/grammer-check"
            style={{ width: "fit-content" }}
          >
           
                <AiOutlineCheck  className="me-2" />
              
            <br />{" "}
            <span style={{ display: "flex", gap: "3px" }}>
              {" "}
              <span>Grammar </span> <span> Check</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "plagiarismCheck" ? "active" : ""
            }`}
            href="/plagiarism-check"
          >
            <AiOutlineCopy className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Plagiarism</span> <span>Check</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "punctuationCheck" ? "active" : ""
            }`}
            href="/punctuation-check"
          >
            <AiOutlineExclamationCircle className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Punctuation</span> <span>Check</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${activeTab === "essayCheck" ? "active" : ""}`}
            href="/essay-check"
          >
            <AiOutlineFileText className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Essay</span> <span>Check</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${activeTab === "spellCheck" ? "active" : ""}`}
            href="/spell-check"
            style={{ width: "fit-content" }}
          >
          
                <AiOutlineCheck  className="me-2" />
           
            <br />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Spell</span> <span>Check</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${activeTab === "wordCount" ? "active" : ""}`}
            href="/word-count"
          >
            <AiOutlineNumber className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Word</span> <span>Count</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "summaryGenerator" ? "active" : ""
            }`}
            href="/summary-generator"
          >
            <AiOutlineFile className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Summary</span> <span>Generator</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "citationGenerator" ? "active" : ""
            }`}
            href="/citation-generator"
          >
            <AiOutlineNumber className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Citation</span> <span>Generator</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${activeTab === "translator" ? "active" : ""}`}
            href="/translator"
          >
            <AiOutlineSearch className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Translator</span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "proofreading" ? "active" : ""
            }`}
            href="/proofreading"
          >
            <AiOutlineCheckCircle className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Proofreading</span>{" "}
              <span style={{ position: "relative" }}>
                <sup
                  style={{
                    color: "green",
                    backgroundColor: "#f0f8ff",
                    borderRadius: "4px",
                    padding: "2px 4px",
                    fontSize: "0.8em",
                  }}
                >
                  Pro
                </sup>
              </span>
            </span>
          </a>
        </span>

        <span className="nav-item">
          <a
            className={`nav-link ${
              activeTab === "superAssistant" ? "active" : ""
            }`}
            href="/super-assistant"
          >
            <AiOutlineRobot className="me-2" />
            <span style={{ display: "flex", gap: "3px" }}>
              <span>Super</span> <span>Assistant</span>{" "}
              <span style={{ position: "relative" }}>
                <sup
                  style={{
                    color: "green",
                    backgroundColor: "#f0f8ff",
                    borderRadius: "4px",
                    padding: "2px 4px",
                    fontSize: "0.8em",
                  }}
                >
                  Pro
                </sup>
              </span>
            </span>
          </a>
        </span>
      </ul>
    </React.Fragment>
  );
};

export default Navigator;
